import { FC } from 'react';
import Link from 'next/link';
import Image from 'next/image';

import { SvgIcon } from '@gate-academy/react';

import {
  FooterWrapper,
  ContactLinksContainer,
  ContactInfo,
  ContactInfoHeading,
  FooterNav,
  FooterNavHeading,
  FooterNavLinks,
  FooterNavLink,
  SocialsContainer,
  CopyrightText,
} from './footer.styles';

import routes from '../../lib/routes';

import { TutorRequestTypes } from '../../utils/tutor-request-types';

import GateLogo from '../../public/gate-academy-white.svg';

const Footer: FC = () => {
  return (
    <FooterWrapper>
      <Image
        src={GateLogo}
        alt="GATE Academy's Logo in White"
        width={163}
        height={66}
        quality={100}
      />

      <ContactLinksContainer>
        <div>
          <ContactInfo>
            <ContactInfoHeading>Lagos</ContactInfoHeading>

            <p>2 Ijesha Close, Ilupeju, Lagos</p>

            <p>
              <a href="tel:+2347086296002">+2347086296002</a>/{' '}
              <a href="tel:+2349123520824">+2349123520824</a>
            </p>
          </ContactInfo>

          <ContactInfo>
            <ContactInfoHeading>United Kingdom</ContactInfoHeading>

            <p>124 City Road, London, EC1V 2NX, United Kingdom</p>
          </ContactInfo>

          <ContactInfo>
            <ContactInfoHeading>Email</ContactInfoHeading>

            <a href="mailto:support@gate-academy.com">
              support@gate-academy.com
            </a>
          </ContactInfo>
        </div>

        <FooterNav>
          <FooterNavHeading>Hire a tutor</FooterNavHeading>

          <FooterNavLinks>
            <FooterNavLink>
              <Link href={routes.hireTutor()}>Academic Tutor</Link>
            </FooterNavLink>

            <FooterNavLink>
              <Link
                href={
                  routes.hireTutor() + `?type=${TutorRequestTypes.LANGUAGE}`
                }
              >
                Language tutor
              </Link>
            </FooterNavLink>

            <FooterNavLink>
              <Link
                href={
                  routes.hireTutor() +
                  `?type=${TutorRequestTypes.TEST_EXAM_PREP}`
                }
              >
                Test/exam prep tutor
              </Link>
            </FooterNavLink>

            <FooterNavLink>
              <Link
                href={
                  routes.hireTutor() + `?type=${TutorRequestTypes.SOFT_SKILLS}`
                }
              >
                Soft skills tutor
              </Link>
            </FooterNavLink>

            <FooterNavLink>
              <Link
                href={
                  routes.hireTutor() +
                  `?type=${TutorRequestTypes.EXTRACURRICULAR}`
                }
              >
                Extracurricular tutor
              </Link>
            </FooterNavLink>

            <FooterNavLink>
              <Link
                href={
                  routes.hireTutor() +
                  `?type=${TutorRequestTypes.SPECIAL_NEEDS}`
                }
              >
                Special needs tutor
              </Link>
            </FooterNavLink>
          </FooterNavLinks>
        </FooterNav>

        <FooterNav>
          <FooterNavHeading>Services</FooterNavHeading>

          <FooterNavLinks>
            {/* <FooterNavLink>
              <Link href={routes.collegeAdmissions()}>College admissions</Link>
            </FooterNavLink> */}

            <FooterNavLink>
              <Link href="/aspire-by-gate">Aspire by GATE</Link>
            </FooterNavLink>

            <FooterNavLink>
              <a href="https://assignmenthelp.gateacademy.com.ng/assignment-order/">
                Writing services
              </a>
            </FooterNavLink>

            <FooterNavLink>
              <a href="https://legacy.gateacademy.com.ng/online-studies/">
                Online tutorials
              </a>
            </FooterNavLink>
          </FooterNavLinks>
        </FooterNav>

        <FooterNav>
          <FooterNavHeading>Helpful links</FooterNavHeading>

          <FooterNavLinks>
            <FooterNavLink>
              <Link href={routes.blog()}>Blog</Link>
            </FooterNavLink>

            <FooterNavLink>
              <Link href={routes.faq()}>FAQs</Link>
            </FooterNavLink>

            <FooterNavLink>
              <Link href={routes.termsAndConditions()}>
                Terms and conditions
              </Link>
            </FooterNavLink>

            <FooterNavLink>
              <Link href={routes.privacyPolicy()}>Privacy policy</Link>
            </FooterNavLink>
          </FooterNavLinks>
        </FooterNav>
      </ContactLinksContainer>

      <SocialsContainer>
        <p>We are social</p>

        <a href="https://twitter.com/gateacademyng">
          <SvgIcon iconName="twitter" />
        </a>

        <a href="https://www.facebook.com/gateacademyng/">
          <SvgIcon iconName="facebook" />
        </a>

        <a href="https://www.instagram.com/gateacademyng/">
          <SvgIcon iconName="instagram" />
        </a>
      </SocialsContainer>

      <CopyrightText>
        Copyright {new Date().getFullYear()}. All rights reserved
      </CopyrightText>
    </FooterWrapper>
  );
};

export default Footer;
